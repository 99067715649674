
// Libraries
import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Components
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import InputFactory from '../../components/inputFactory'
import Button from '../../components/button'
import DuoSection from '../../components/duoSection'
import UserService from "../../services/user";

export default function ChangePasswordPage() {

	const [formData, setFormData] = useState({}),
	[errors, setErrors] = useState({})

	const handleInputChange = (value, name, scope) => {
		if (value !== undefined) {
			// Update field value
			if (scope) {
				formData[scope][name] = value
			}
			else {
				formData[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete formData[scope][name]
			}
			else {
				delete formData[name]
			}
		}

		// Update state of file input values
		setFormData({
			...formData
		})
	}

	const handleSubmit = async event => {
		event.preventDefault()

		if(Object.keys(formData).length === 0) return

		const userService = new UserService()

		const response = await userService.updateUser(formData)

		// Check if request was successful or if any validation errors
		if(!response?.success || response?.errors) {
			// Add validation errors to state
			setErrors(response?.errors)
		}
		else {
			return navigate('/profile')
		}
	}

	return (
		<Layout className="profile-change-password nav-blue-half">
			<Seo title="Profile Change Password" />
			<DuoSection ProfileChangePassword>
				<div>
					<div className="content-wrap">
						<h1 className="color--endeavour">Change Password</h1>
					</div>
				</div>
				<div>
					<div className="content-wrap">
						<form onSubmit={handleSubmit}>
                        	<InputFactory
								type="password"
                                label="Old Password*"
								name="current_password"
								placeholder="Min 6 characters"
								value={formData?.current_password}
								error={errors?.current_password}
								onChange={handleInputChange}
								className="new-text-input"
							/>
                            <InputFactory
								type="password"
                                label="New Password*"
								name="password"
								placeholder="Min 6 characters"
								value={formData?.password}
								error={errors?.password}
								onChange={handleInputChange}
								className="new-text-input"
							/>
                            <InputFactory
								type="password"
                                label="Confirm Password*"
								name="password_confirmation"
								placeholder="Min 6 characters"
								value={formData?.password_confirmation}
								error={errors?.password_confirmation}
								onChange={handleInputChange}
								className="new-text-input"
							/>

							<Button type="submit" block>Change Password</Button>
						</form>
					</div>
				</div>
			</DuoSection>
		</Layout>
	)
}